import { LocalKey } from '@utils/storage'

import { ConfirmationDismissType } from '@components/confirmation'
import { NotificationPosition } from '@components/notifications'
import { ModalDismissType } from '@elements/index'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api'

const DateFormat = {
  base: 'yyyy-MM-dd',
  detail: 'yyyy-MM-dd HH:mm:ss',
  table: 'MM-dd-yyyy',
  bbo: 'dd/MM/yyyy',
  bboDetail: 'dd/MM/yyyy HH:mm:ss',
  bboNoTime: 'dd/MM/yyyy HH:mm',
  insurance: 'dd-MMM-yyyy HH:mm',
}

const storageKey = {
  authToken: new LocalKey<string | null | undefined>('auth-token', ''),
}

enum Action {
  DETAIL = 'view',
  EDIT = 'edit',
  CREATE = 'create',
}

const usersRole = {
  sysadmin: 'sysadmin',
  staffKeuangan: 'staff_keuangan',
  managerKeuangan: 'manager_keuangan',
  staffKemitraan: 'staff_kemitraan',
  managerKemitraan: 'manager_kemitraan',
  staffPu: 'staff_pu',
  managerPu: 'manager_pu',
  staffPenjualan: 'staff_penjualan',
  managerPenjualan: 'manager_penjualan',
  vpNiaga: 'vp_niaga',
  staffOperasi: 'staff_operasi',
  managerOperasi: 'manager_operasi',
  vpOperasi: 'vp_operasi',
  mitraKso: 'mitra_kso',
  agenAsuransi: 'agen_asuransi',
}

const moduleName = {
  dashboard: 'dashboard',
  dashboardPenjualan: 'dashboard_penjualan',
  dashboardNiaga: 'dashboard_niaga',
  dashboardBBO: 'dashboard_bbo',
  userManagement: 'user_management',
  kontrakKso: 'kontrak_kso',
  kontrakPelanggan: 'kontrak_pelanggan',
  routeMitraKso: 'route_mitra_kso',
  routePemberiKerja: 'route_pemberi_kerja',
  entriShipment: 'entri_shipment',
  alokasiKapal: 'alokasi_kapal',
  agendaRakor: 'agenda_rakor',
  deviasiShipment: 'deviasi_shipment',
  shipmentInstruction: 'shipment_instruction',
  shipmentOperation: 'shipment_operation',
  arsipShipment: 'arsip_operation',
  penagihan: 'penagihan',
  penagihanMitraKso: 'penagihan_mitra_kso',
  penagihanPemberiKerja: 'penagihan_pemberi_kerja',
  // notaDinasPenagihan: 'nota_dinas_penagihan',
  mdPemberiKerja: 'md_pemberi_kerja',
  mdMitraKso: 'md_mitra_kso',
  mdPemasok: 'md_pemasok',
  mdKapal: 'md_kapal',
  mdTermin: 'md_termin',
  mdModa: 'md_moda',
  mdPol: 'md_pol',
  mdPod: 'md_pod',
  pengaturanBbmBulanan: 'pengaturan_bbm_bulanan',
  bbmBerjalan: 'bbm_berjalan',
  kontrakPemberiKerja: 'kontrak_pemberi_kerja',
  mdRouteShipment: 'md_route_shipment',
  profile: 'profile',
  sailingInstruction: 'sailing_instruction',
  shipment: 'shipment',
  kontrakMitraKSO: 'kontrak_mitra_kso',
  operation: 'operation',
  spal: 'spal',
  penagihanMitra: 'penagihan_mitra',
  requestSI: 'request_SI',
  updateOrder: 'update_order',
  pembayaranMitra: 'pembayaran_mitra',
  documentOS: 'document_operation_shipment',
  pendapatanBatuBara: 'pendapatan_batubara',
  rasioOperasionalKso: 'rasio_operasional_kso',
  shipmentTepatWaktu: 'shipment_tepat_waktu',
  amandemenSpal: 'amandemen_spal',
  laporanCashIn: 'laporan_cash_in',
  laporanCashOut: 'laporan_cash_out',
  laporanShipmentBelumBertarif: 'laporan_shipment_belum_bertarif',
  laporanShipmentTBN: 'laporan_shipment_tbn',
  laporanShipmentKSO: 'laporan_shipment_kso',
  laporanTonaseBL: 'laporan_tonase_bl',
  laporanIdlePemasok: 'laporan_idle_pemasok',
  laporanPengangkutanBatubara: 'laporan_pengangkutan_batubara',
  laporanKelengkapanDokumen: 'laporan_kelengkapan_dokumen',
  laporanShipmentPltu: 'laporan_shipment_pltu',
  laporanIdlePltu: 'laporan_idle_pltu',
  shiplink: 'shiplink',
  mdFormula: 'md_formula',
  mdHargaTriwulan: 'md_harga_triwulan',
  asuransi: 'asuransi',
  klaimAsuransi: 'klaim_asuransi',
  listAsuransi: 'list_asuransi',
  monitoring: 'monitoring',
  listSla: 'list_sla',
}

const roleAction = {
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete',
  activeInActive: 'active_in_active',
  readOwned: 'read_owned',
  updateOwned: 'update_owned',
  approve: 'approve',
  reject: 'reject',
}

const roleGroup = {
  C: [roleAction.create],
  R: [roleAction.read],
  U: [roleAction.update],
  D: [roleAction.delete],
  CR: [roleAction.create, roleAction.read],
  CU: [roleAction.create, roleAction.update],
  CD: [roleAction.create, roleAction.delete],
  RU: [roleAction.read, roleAction.update],
  RD: [roleAction.read, roleAction.delete],
  UD: [roleAction.update, roleAction.delete],
  CRU: [roleAction.create, roleAction.read, roleAction.update],
  CUD: [roleAction.create, roleAction.update, roleAction.delete],
  RUD: [roleAction.read, roleAction.update, roleAction.delete],
  CRUD: [
    roleAction.create,
    roleAction.read,
    roleAction.update,
    roleAction.delete,
  ],
  CRD: [
    roleAction.create,
    roleAction.read,
    roleAction.update,
    roleAction.delete,
  ],
  RO: [roleAction.readOwned],
  ROUO: [roleAction.read, roleAction.readOwned, roleAction.updateOwned],
  A: [roleAction.read, roleAction.approve],
  RE: [roleAction.read, roleAction.reject],
  ARE: [roleAction.read, roleAction.approve, roleAction.reject],
  RUARE: [
    roleAction.read,
    roleAction.update,
    roleAction.approve,
    roleAction.reject,
  ],
  CRUDARE: [
    roleAction.create,
    roleAction.read,
    roleAction.update,
    roleAction.delete,
    roleAction.approve,
    roleAction.reject,
  ],
  RARE: [
    roleAction.read,
    roleAction.delete,
    roleAction.approve,
    roleAction.reject,
  ],
  RUOAR: [
    roleAction.read,
    roleAction.updateOwned,
    roleAction.approve,
    roleAction.reject,
  ],
}

const publicRoleMatiks = [moduleName.profile]

const roleMatriks = {
  [usersRole.sysadmin]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.dashboardPenjualan]: roleGroup.R,
    [moduleName.dashboardNiaga]: roleGroup.R,
    [moduleName.deviasiShipment]: roleGroup.CRUD,
    [moduleName.userManagement]: [...roleGroup.CRUD, roleAction.activeInActive],
    [moduleName.kontrakKso]: roleGroup.CRUD,
    [moduleName.kontrakPelanggan]: roleGroup.CRUD,
    [moduleName.routeMitraKso]: roleGroup.CRUD,
    [moduleName.routePemberiKerja]: roleGroup.CRUD,
    [moduleName.entriShipment]: roleGroup.CRUD,
    [moduleName.alokasiKapal]: roleGroup.CRUDARE,
    [moduleName.updateOrder]: roleGroup.RU,
    [moduleName.agendaRakor]: roleGroup.CRUD,
    [moduleName.shipmentInstruction]: roleGroup.CRUDARE,
    [moduleName.shipmentOperation]: roleGroup.RD,
    [moduleName.shipmentOperation]: roleGroup.RD,
    [moduleName.penagihan]: roleGroup.CRUD,
    [moduleName.penagihanMitraKso]: roleGroup.ARE,
    [moduleName.penagihanPemberiKerja]: roleGroup.ARE,
    // [moduleName.notaDinasPenagihan]: roleGroup.CRUD,
    [moduleName.mdPemberiKerja]: roleGroup.CRUD,
    [moduleName.mdMitraKso]: roleGroup.CRUD,
    [moduleName.mdPemasok]: roleGroup.CRUD,
    [moduleName.mdKapal]: roleGroup.CRUD,
    [moduleName.mdTermin]: roleGroup.CRUD,
    [moduleName.mdModa]: roleGroup.CRUD,
    [moduleName.mdPol]: roleGroup.CRUD,
    [moduleName.mdPod]: roleGroup.CRUD,
    [moduleName.mdRouteShipment]: roleGroup.CRUD,
    [moduleName.bbmBerjalan]: roleGroup.CRUD,
    [moduleName.pengaturanBbmBulanan]: [],
    [moduleName.kontrakPemberiKerja]: roleGroup.CRUDARE,
    [moduleName.sailingInstruction]: roleGroup.CRUDARE,
    [moduleName.shipment]: roleGroup.CRUD,
    [moduleName.kontrakMitraKSO]: roleGroup.CRUDARE,
    [moduleName.operation]: roleGroup.CRUD,
    [moduleName.spal]: roleGroup.RUOAR,
    [moduleName.bbmBerjalan]: roleGroup.CRU,
    [moduleName.requestSI]: roleGroup.CRU,
    [moduleName.pembayaranMitra]: roleGroup.ARE,
    [moduleName.documentOS]: roleGroup.CRUDARE,
    [moduleName.pendapatanBatuBara]: roleGroup.R,
    [moduleName.rasioOperasionalKso]: roleGroup.R,
    [moduleName.shipmentTepatWaktu]: roleGroup.R,
    [moduleName.amandemenSpal]: roleGroup.CRUD,
    [moduleName.laporanCashIn]: roleGroup.R,
    [moduleName.laporanCashOut]: roleGroup.R,
    [moduleName.laporanShipmentBelumBertarif]: roleGroup.R,
    [moduleName.laporanShipmentTBN]: roleGroup.R,
    [moduleName.laporanShipmentKSO]: roleGroup.R,
    [moduleName.laporanTonaseBL]: roleGroup.R,
    [moduleName.laporanIdlePemasok]: roleGroup.R,
    [moduleName.laporanPengangkutanBatubara]: roleGroup.R,
    [moduleName.laporanKelengkapanDokumen]: roleGroup.R,
    [moduleName.laporanShipmentPltu]: roleGroup.R,
    [moduleName.laporanIdlePltu]: roleGroup.R,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.mdFormula]: roleGroup.CRD,
    [moduleName.dashboardBBO]: roleGroup.R,
    [moduleName.mdHargaTriwulan]: roleGroup.CRUD,
    [moduleName.asuransi]: roleGroup.R,
    [moduleName.klaimAsuransi]: roleGroup.R,
    [moduleName.listAsuransi]: roleGroup.R,
    [moduleName.listSla]: roleGroup.R,
  },
  [usersRole.staffPu]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.mdPemberiKerja]: roleGroup.R,
    [moduleName.mdPol]: roleGroup.R,
    [moduleName.mdPod]: roleGroup.R,
    [moduleName.kontrakPemberiKerja]: roleGroup.R,
    [moduleName.kontrakMitraKSO]: roleGroup.R,
    [moduleName.shipment]: roleGroup.CRU,
    [moduleName.deviasiShipment]: roleGroup.CRUD,
    [moduleName.sailingInstruction]: roleGroup.R,
    [moduleName.agendaRakor]: roleGroup.CRUD,
    [moduleName.shipmentOperation]: roleGroup.R,
    [moduleName.penagihanPemberiKerja]: roleGroup.R,
    [moduleName.penagihanMitraKso]: roleGroup.ARE,
    [moduleName.bbmBerjalan]: roleGroup.CRUD,
    [moduleName.pembayaranMitra]: roleGroup.ARE,
    [moduleName.documentOS]: roleGroup.ARE,
    [moduleName.shipmentTepatWaktu]: roleGroup.R,
    [moduleName.laporanKelengkapanDokumen]: roleGroup.R,
    [moduleName.laporanShipmentTBN]: roleGroup.R,
    [moduleName.laporanPengangkutanBatubara]: roleGroup.R,
    [moduleName.laporanIdlePemasok]: roleGroup.R,
    [moduleName.laporanIdlePltu]: roleGroup.R,
    [moduleName.laporanShipmentPltu]: roleGroup.R,
    [moduleName.laporanShipmentKSO]: roleGroup.R,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.listSla]: roleGroup.R,
  },
  [usersRole.managerPu]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.dashboardPenjualan]: roleGroup.R,
    [moduleName.dashboardNiaga]: roleGroup.R,
    [moduleName.mdPemberiKerja]: roleGroup.R,
    [moduleName.mdPol]: roleGroup.R,
    [moduleName.mdPod]: roleGroup.R,
    [moduleName.kontrakPemberiKerja]: roleGroup.R,
    [moduleName.kontrakMitraKSO]: roleGroup.R,
    [moduleName.mdRouteShipment]: roleGroup.R,
    [moduleName.shipment]: roleGroup.CRUD,
    [moduleName.sailingInstruction]: roleGroup.ARE,
    [moduleName.agendaRakor]: roleGroup.CRUD,
    [moduleName.shipmentOperation]: roleGroup.ARE,
    [moduleName.penagihanPemberiKerja]: roleGroup.R,
    [moduleName.penagihanMitraKso]: roleGroup.ARE,
    [moduleName.bbmBerjalan]: roleGroup.CRUD,
    [moduleName.pembayaranMitra]: roleGroup.ARE,
    [moduleName.shipmentTepatWaktu]: roleGroup.R,
    [moduleName.laporanKelengkapanDokumen]: roleGroup.R,
    [moduleName.laporanShipmentTBN]: roleGroup.R,
    [moduleName.laporanPengangkutanBatubara]: roleGroup.R,
    [moduleName.laporanIdlePemasok]: roleGroup.R,
    [moduleName.laporanIdlePltu]: roleGroup.R,
    [moduleName.laporanShipmentPltu]: roleGroup.R,
    [moduleName.laporanShipmentKSO]: roleGroup.R,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.asuransi]: roleGroup.R,
    [moduleName.klaimAsuransi]: roleGroup.R,
    [moduleName.listSla]: roleGroup.R,
  },
  [usersRole.staffPenjualan]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.mdPemberiKerja]: roleGroup.CRUD,
    [moduleName.mdPol]: roleGroup.CRUD,
    [moduleName.mdPod]: roleGroup.CRUD,
    [moduleName.mdModa]: roleGroup.CRUD,
    [moduleName.mdTermin]: roleGroup.CRUD,
    [moduleName.mdPemasok]: roleGroup.CRUD,
    [moduleName.mdMitraKso]: roleGroup.R,
    [moduleName.updateOrder]: roleGroup.RU,
    [moduleName.kontrakPemberiKerja]: roleGroup.CRUD,
    [moduleName.kontrakMitraKSO]: roleGroup.R,
    [moduleName.mdRouteShipment]: roleGroup.CRUD,
    [moduleName.shipment]: roleGroup.ROUO,
    [moduleName.shipmentOperation]: roleGroup.R,
    [moduleName.penagihanPemberiKerja]: roleGroup.R,
    [moduleName.penagihanMitraKso]: roleGroup.ARE,
    [moduleName.bbmBerjalan]: roleGroup.R,
    [moduleName.mdFormula]: roleGroup.CRD,
    [moduleName.pembayaranMitra]: roleGroup.R,
    [moduleName.pendapatanBatuBara]: roleGroup.R,
    [moduleName.rasioOperasionalKso]: roleGroup.R,
    [moduleName.shipmentTepatWaktu]: roleGroup.R,
    [moduleName.laporanCashIn]: roleGroup.R,
    [moduleName.laporanCashOut]: roleGroup.R,
    [moduleName.laporanKelengkapanDokumen]: roleGroup.R,
    [moduleName.laporanShipmentBelumBertarif]: roleGroup.R,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.mdFormula]: roleGroup.CRD,
    [moduleName.dashboardBBO]: roleGroup.R,
    [moduleName.listSla]: roleGroup.R,
  },
  [usersRole.managerPenjualan]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.dashboardPenjualan]: roleGroup.R,
    [moduleName.dashboardNiaga]: roleGroup.R,
    [moduleName.mdPemberiKerja]: roleGroup.CRUD,
    [moduleName.mdPol]: roleGroup.CRUD,
    [moduleName.mdPod]: roleGroup.CRUD,
    [moduleName.mdModa]: roleGroup.CRUD,
    [moduleName.mdTermin]: roleGroup.CRUD,
    [moduleName.mdFormula]: roleGroup.CRD,
    [moduleName.mdPemasok]: roleGroup.R,
    [moduleName.mdMitraKso]: roleGroup.R,
    [moduleName.kontrakPemberiKerja]: roleGroup.RARE,
    [moduleName.kontrakMitraKSO]: roleGroup.R,
    [moduleName.mdRouteShipment]: roleGroup.CRUDARE,
    [moduleName.shipment]: roleGroup.R,
    [moduleName.updateOrder]: roleGroup.RU,
    [moduleName.shipmentOperation]: roleGroup.R,
    [moduleName.penagihanPemberiKerja]: roleGroup.R,
    [moduleName.penagihanMitraKso]: roleGroup.ARE,
    [moduleName.bbmBerjalan]: roleGroup.R,
    [moduleName.pembayaranMitra]: roleGroup.R,
    [moduleName.pendapatanBatuBara]: roleGroup.R,
    [moduleName.rasioOperasionalKso]: roleGroup.R,
    [moduleName.shipmentTepatWaktu]: roleGroup.R,
    [moduleName.laporanCashIn]: roleGroup.R,
    [moduleName.laporanCashOut]: roleGroup.R,
    [moduleName.laporanKelengkapanDokumen]: roleGroup.R,
    [moduleName.laporanShipmentBelumBertarif]: roleGroup.R,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.mdFormula]: roleGroup.CRD,
    [moduleName.dashboardBBO]: roleGroup.R,
    [moduleName.listSla]: roleGroup.R,
  },
  [usersRole.staffKemitraan]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.mdPemberiKerja]: roleGroup.R,
    [moduleName.mdPol]: roleGroup.R,
    [moduleName.mdPod]: roleGroup.R,
    [moduleName.mdModa]: roleGroup.R,
    [moduleName.mdTermin]: roleGroup.CRUD,
    [moduleName.mdKapal]: roleGroup.CRUD,
    [moduleName.mdPemasok]: roleGroup.R,
    [moduleName.mdMitraKso]: roleGroup.CRUD,
    [moduleName.kontrakPemberiKerja]: roleGroup.R,
    [moduleName.kontrakMitraKSO]: roleGroup.CRUD,
    [moduleName.mdRouteShipment]: roleGroup.R,
    [moduleName.alokasiKapal]: roleGroup.CRU,
    [moduleName.shipment]: roleGroup.RU,
    [moduleName.sailingInstruction]: roleGroup.R,
    [moduleName.agendaRakor]: roleGroup.CRUD,
    [moduleName.spal]: roleGroup.RUOAR,
    [moduleName.shipmentOperation]: roleGroup.R,
    [moduleName.penagihanPemberiKerja]: roleGroup.R,
    [moduleName.penagihanMitraKso]: roleGroup.R,
    [moduleName.bbmBerjalan]: roleGroup.CRUD,
    [moduleName.pembayaranMitra]: roleGroup.RUARE,
    [moduleName.amandemenSpal]: roleGroup.CRUD,
    [moduleName.shipmentTepatWaktu]: roleGroup.R,
    [moduleName.laporanCashOut]: roleGroup.R,
    [moduleName.laporanKelengkapanDokumen]: roleGroup.R,
    [moduleName.laporanShipmentBelumBertarif]: roleGroup.R,
    [moduleName.laporanTonaseBL]: roleGroup.R,
    [moduleName.laporanShipmentTBN]: roleGroup.R,
    [moduleName.laporanShipmentPltu]: roleGroup.R,
    [moduleName.laporanShipmentKSO]: roleGroup.R,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.asuransi]: roleGroup.R,
    [moduleName.klaimAsuransi]: roleGroup.R,
  },
  [usersRole.managerKemitraan]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.dashboardPenjualan]: roleGroup.R,
    [moduleName.dashboardNiaga]: roleGroup.R,
    [moduleName.mdPemberiKerja]: roleGroup.R,
    [moduleName.mdPol]: roleGroup.CRUD,
    [moduleName.mdPod]: roleGroup.CRUD,
    [moduleName.mdModa]: roleGroup.CRUD,
    [moduleName.mdTermin]: roleGroup.CRUD,
    [moduleName.mdKapal]: roleGroup.CRUD,
    [moduleName.mdPemasok]: roleGroup.R,
    [moduleName.mdMitraKso]: roleGroup.CRUD,
    [moduleName.kontrakPemberiKerja]: roleGroup.R,
    [moduleName.kontrakMitraKSO]: roleGroup.ARE,
    [moduleName.shipment]: roleGroup.ARE,
    [moduleName.alokasiKapal]: roleGroup.ARE,
    [moduleName.mdRouteShipment]: roleGroup.R,
    [moduleName.sailingInstruction]: roleGroup.R,
    [moduleName.agendaRakor]: roleGroup.CRUD,
    [moduleName.spal]: roleGroup.ARE,
    [moduleName.shipmentOperation]: roleGroup.R,
    [moduleName.penagihanPemberiKerja]: roleGroup.R,
    [moduleName.penagihanMitraKso]: roleGroup.R,
    [moduleName.bbmBerjalan]: roleGroup.CRUD,
    [moduleName.pembayaranMitra]: roleGroup.ARE,
    [moduleName.shipmentTepatWaktu]: roleGroup.R,
    [moduleName.laporanCashOut]: roleGroup.R,
    [moduleName.laporanKelengkapanDokumen]: roleGroup.R,
    [moduleName.laporanShipmentBelumBertarif]: roleGroup.R,
    [moduleName.laporanTonaseBL]: roleGroup.R,
    [moduleName.laporanShipmentTBN]: roleGroup.R,
    [moduleName.laporanShipmentPltu]: roleGroup.R,
    [moduleName.laporanShipmentKSO]: roleGroup.R,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.asuransi]: roleGroup.R,
    [moduleName.klaimAsuransi]: roleGroup.R,
    [moduleName.listSla]: roleGroup.R,
  },
  [usersRole.vpNiaga]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.dashboardPenjualan]: roleGroup.R,
    [moduleName.dashboardNiaga]: roleGroup.R,
    [moduleName.mdPemberiKerja]: roleGroup.R,
    [moduleName.mdPol]: roleGroup.R,
    [moduleName.mdPod]: roleGroup.R,
    [moduleName.mdModa]: roleGroup.R,
    [moduleName.mdTermin]: roleGroup.R,
    [moduleName.mdKapal]: roleGroup.R,
    [moduleName.mdPemasok]: roleGroup.R,
    [moduleName.mdMitraKso]: roleGroup.R,
    [moduleName.kontrakPemberiKerja]: roleGroup.R,
    [moduleName.kontrakMitraKSO]: roleGroup.R,
    [moduleName.mdRouteShipment]: roleGroup.R,
    [moduleName.shipment]: roleGroup.R,
    [moduleName.sailingInstruction]: roleGroup.RUARE,
    [moduleName.agendaRakor]: roleGroup.R,
    [moduleName.spal]: roleGroup.ARE,
    [moduleName.shipmentOperation]: roleGroup.R,
    [moduleName.penagihanPemberiKerja]: roleGroup.ARE,
    [moduleName.penagihanMitraKso]: roleGroup.ARE,
    [moduleName.bbmBerjalan]: roleGroup.R,
    [moduleName.pembayaranMitra]: roleGroup.ARE,
    [moduleName.pendapatanBatuBara]: roleGroup.R,
    [moduleName.rasioOperasionalKso]: roleGroup.R,
    [moduleName.shipmentTepatWaktu]: roleGroup.R,
    [moduleName.laporanCashIn]: roleGroup.R,
    [moduleName.laporanCashOut]: roleGroup.R,
    [moduleName.laporanKelengkapanDokumen]: roleGroup.R,
    [moduleName.laporanShipmentBelumBertarif]: roleGroup.R,
    [moduleName.laporanTonaseBL]: roleGroup.R,
    [moduleName.laporanShipmentTBN]: roleGroup.R,
    [moduleName.laporanPengangkutanBatubara]: roleGroup.R,
    [moduleName.laporanIdlePemasok]: roleGroup.R,
    [moduleName.laporanIdlePltu]: roleGroup.R,
    [moduleName.laporanShipmentPltu]: roleGroup.R,
    [moduleName.laporanShipmentKSO]: roleGroup.R,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.listSla]: roleGroup.R,
  },
  // Check lagi shipmentOperation dan penagihanPemberiKerja
  [usersRole.staffOperasi]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.mdKapal]: roleGroup.CRUD,
    [moduleName.mdRouteShipment]: roleGroup.R,
    [moduleName.kontrakPemberiKerja]: roleGroup.R,
    [moduleName.shipment]: roleGroup.R,
    [moduleName.shipmentOperation]: roleGroup.R,
    [moduleName.penagihanPemberiKerja]: roleGroup.R,
    [moduleName.bbmBerjalan]: roleGroup.R,
    [moduleName.requestSI]: roleGroup.CRU,
    [moduleName.documentOS]: roleGroup.U,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.asuransi]: roleGroup.R,
    [moduleName.klaimAsuransi]: roleGroup.R,
    [moduleName.laporanKelengkapanDokumen]: roleGroup.R,
    [moduleName.shipmentTepatWaktu]: roleGroup.R,
    [moduleName.listSla]: roleGroup.R,
  },
  [usersRole.managerOperasi]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.dashboardPenjualan]: roleGroup.R,
    [moduleName.dashboardNiaga]: roleGroup.R,
    [moduleName.mdKapal]: roleGroup.CRUD,
    [moduleName.mdRouteShipment]: roleGroup.R,
    [moduleName.shipment]: roleGroup.R,
    [moduleName.shipmentOperation]: roleGroup.ARE,
    [moduleName.penagihanPemberiKerja]: roleGroup.ARE,
    [moduleName.bbmBerjalan]: roleGroup.R,
    [moduleName.requestSI]: roleGroup.CRU,
    [moduleName.documentOS]: roleGroup.ARE,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.laporanKelengkapanDokumen]: roleGroup.R,
    [moduleName.shipmentTepatWaktu]: roleGroup.R,
    [moduleName.klaimAsuransi]: roleGroup.R,
    [moduleName.listAsuransi]: roleGroup.R,
    [moduleName.listSla]: roleGroup.R,
  },
  [usersRole.vpOperasi]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.dashboardPenjualan]: roleGroup.R,
    [moduleName.dashboardNiaga]: roleGroup.R,
    [moduleName.mdKapal]: roleGroup.R,
    [moduleName.mdRouteShipment]: roleGroup.R,
    [moduleName.shipment]: roleGroup.R,
    [moduleName.shipmentOperation]: roleGroup.ARE,
    [moduleName.penagihanPemberiKerja]: roleGroup.ARE,
    [moduleName.bbmBerjalan]: roleGroup.R,
    [moduleName.requestSI]: roleGroup.CRU,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.laporanKelengkapanDokumen]: roleGroup.R,
    [moduleName.shipmentTepatWaktu]: roleGroup.R,
    [moduleName.listSla]: roleGroup.R,
  },
  // Check lagi shipment operation dan penagihan mitra kso
  [usersRole.mitraKso]: {
    [moduleName.dashboard]: roleGroup.R,
    [moduleName.mdKapal]: roleGroup.CRUD,
    [moduleName.routePemberiKerja]: roleGroup.R,
    [moduleName.routeMitraKso]: roleGroup.CRUD,
    [moduleName.shipment]: roleGroup.RU,
    [moduleName.spal]: roleGroup.ROUO,
    [moduleName.shipmentOperation]: roleGroup.R,
    [moduleName.requestSI]: roleGroup.CR,
    [moduleName.pembayaranMitra]: roleGroup.RU,
    [moduleName.alokasiKapal]: roleGroup.ROUO,
    [moduleName.documentOS]: roleGroup.U,
    [moduleName.asuransi]: roleGroup.R,
    [moduleName.klaimAsuransi]: roleGroup.R,
  },
  [usersRole.staffKeuangan]: {
    [moduleName.shipmentOperation]: roleGroup.R,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.asuransi]: roleGroup.R,
    [moduleName.klaimAsuransi]: roleGroup.R,
    [moduleName.listAsuransi]: roleGroup.R,
  },
  [usersRole.managerKeuangan]: {
    [moduleName.shipmentOperation]: roleGroup.R,
    [moduleName.shiplink]: roleGroup.R,
    [moduleName.asuransi]: roleGroup.R,
    [moduleName.klaimAsuransi]: roleGroup.R,
    [moduleName.listAsuransi]: roleGroup.R,
  },
  [usersRole.agenAsuransi]: {
    [moduleName.asuransi]: roleGroup.R,
    [moduleName.klaimAsuransi]: roleGroup.R,
    [moduleName.listAsuransi]: roleGroup.R,
    // [moduleName.shiplink]: roleGroup.R,
    // [moduleName.shipmentOperation]: roleGroup.R,
  },
}

const wilayah = {
  wilayah1: 'wilayah-1',
  wilayah2: 'wilayah-2',
  wilayah3: 'wilayah-3',
  wilayah4: 'wilayah-4',
}

const jenisKontrak = {
  spal: 'spal',
  kontrakPemberiKerja: 'kontrak_pemberi_kerja',
  kontrakMitraKso: 'kontrak_mitra_kso',
}

const componentConfig = {
  notification: {
    interval: 2500,
    position: NotificationPosition.topRight,
  },
  confirmation: {
    dismiss: {
      enabled: true,
      escapeKey: false,
      outsidePress: false,
      closeButton: false,
    } as ConfirmationDismissType,
    confirm: {
      title: 'Apakah kamu yakin?',
      message: 'Jika melanjutkan maka data yang sudah dimasukan akan hilang!',
    },
    alert: {
      title: 'Berhasil',
      message: 'Konten berhasil dibuat!',
    },
  },
  modal: {
    dismiss: {
      enabled: true,
      escapeKey: false,
      outsidePress: false,
      closeButton: false,
    } as ModalDismissType,
  },
}

const jenisKapal = {
  spb: 'spb',
  vessel: 'vessel',
  tb_bg: 'tb-bg',
}

const tipeKapal = {
  spb: 'spb',
  vessel: 'vessel',
  tb: 'tb',
  bg: 'bg',
}

const kepemilikan = {
  sendiri: 'sendiri',
  kontrak: 'kontrak',
}

const schema_bbm = {
  schema_1: 1,
  schema_2: 2,
  schema_3: 3,
  schema_4: 4,
}

const format_durasi = {
  durasi_hari: 'durasi_hari',
  loading_rate: 'loading_rate',
}

const options = {
  kepemilikan: [
    {
      label: 'Milik Sendiri',
      value: kepemilikan.sendiri,
    },
    {
      label: 'Mitra',
      value: kepemilikan.kontrak,
    },
  ],
  wilayah: [
    {
      label: 'Wilayah I',
      value: wilayah.wilayah1,
    },
    {
      label: 'Wilayah II',
      value: wilayah.wilayah2,
    },
    {
      label: 'Wilayah III',
      value: wilayah.wilayah3,
    },
    {
      label: 'Wilayah IV',
      value: wilayah.wilayah4,
    },
  ],
  role: [
    {
      label: 'Sys Admin',
      value: usersRole.sysadmin,
    },
    {
      label: 'Staff Asuransi',
      value: usersRole.staffKeuangan,
    },
    {
      label: 'Manager Asuransi',
      value: usersRole.managerKeuangan,
    },
    {
      label: 'Staff Kemitraan',
      value: usersRole.staffKemitraan,
    },
    {
      label: 'Manager Kemitraan',
      value: usersRole.managerKemitraan,
    },
    {
      label: 'Staff PU',
      value: usersRole.staffPu,
    },
    {
      label: 'Manager PU',
      value: usersRole.managerPu,
    },
    {
      label: 'Staff Penjualan',
      value: usersRole.staffPenjualan,
    },
    {
      label: 'Manager Penjualan',
      value: usersRole.managerPenjualan,
    },
    {
      label: 'VP Niaga',
      value: usersRole.vpNiaga,
    },
    {
      label: 'Staff Operasi',
      value: usersRole.staffOperasi,
    },
    {
      label: 'Manager Operasi',
      value: usersRole.managerOperasi,
    },
    {
      label: 'VP Operasi',
      value: usersRole.vpOperasi,
    },
    {
      label: 'Mitra KSO',
      value: usersRole.mitraKso,
    },
    {
      label: 'Agen Asuransi',
      value: usersRole.agenAsuransi,
    },
  ],
  schema_bbm: [
    {
      label: 'Skema 1 BBM',
      value: schema_bbm.schema_1,
    },
    {
      label: 'Skema 2 BBM',
      value: schema_bbm.schema_2,
    },
    {
      label: 'Skema Paiton',
      value: schema_bbm.schema_3,
    },
    {
      label: 'Skema SLBM',
      value: schema_bbm.schema_4,
    },
  ],
  format_durasi_demurrage: [
    {
      label: 'Durasi Hari',
      value: format_durasi.durasi_hari,
    },
    {
      label: 'Loading Rate',
      value: format_durasi.loading_rate,
    },
  ],
  kuartal: [
    {
      label: 'I',
      value: '1',
    },
    {
      label: 'II',
      value: '2',
    },
    {
      label: 'III',
      value: '3',
    },
    {
      label: 'IV',
      value: '4',
    },
  ],
  month: [
    {
      label: 'Januari',
      value: '1',
    },
    {
      label: 'Februari',
      value: '2',
    },
    {
      label: 'Maret',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'Mei',
      value: '5',
    },
    {
      label: 'Juni',
      value: '6',
    },
    {
      label: 'Juli',
      value: '7',
    },
    {
      label: 'Agustus',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'Oktober',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'Desember',
      value: '12',
    },
  ],
  kontrak_mitra_shiplink: [
    {
      label: 'SPAL',
      value: 'spal',
    },
    {
      label: 'Kontrak Mitra',
      value: 'kontrak',
    },
    {
      label: '-',
      value: 'bag',
    },
  ],
  timeline_shiplink: [
    {
      label: 'Timeline Pengiriman',
      value: 'pengiriman',
    },
    {
      label: 'Timeline Penagihan',
      value: 'penagihan',
    },
    {
      label: 'Timeline Pembayaran',
      value: 'pembayaran',
    },
  ],
  statusShipmentPemberiKerja: [
    {
      label: 'Pendapatan',
      value: 'penagihan',
    },
    {
      label: 'Shipment',
      value: 'shipment',
    },
    {
      label: 'Volume',
      value: 'volume_bl',
    },
  ],
}

const maskCurrency = {
  rupiah: {
    mask: Number,
    radix: ',',
    scale: 2,
    signed: true,
    thousandsSeparator: ',',
    padFractionalZeros: false,
    normalizeZeros: true,
  },
}

const monthsLabelsChart = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Agust',
  'Sept',
  'Oct',
  'Nov',
  'Des',
]

const mitraIsBAg = {
  value: '00000000-0000-0000-0000-000000000000',
  label: 'PT PELAYARAN BAHTERA ADHIGUNA',
}

export {
  Action,
  API_BASE_URL,
  componentConfig,
  DateFormat,
  format_durasi,
  jenisKapal,
  jenisKontrak,
  maskCurrency,
  mitraIsBAg,
  moduleName,
  monthsLabelsChart,
  options,
  publicRoleMatiks,
  roleAction,
  roleMatriks,
  storageKey,
  tipeKapal,
  usersRole,
  wilayah,
}
